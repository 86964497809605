import React, { useEffect, useState, useRef } from 'react';
import StyledPage from './StyledPage';
import Modal from '../../../components/Modal';
import { H1, P } from '../../../components/Typography';
import Spacer from '../../../components/Spacer';
import ButtonComponent from '../../../components/Button';
import Progress from '../../../components/Progress';
import Header from '../../../components/Header';
import Button from '@mui/material/Button';
import { LOADIPHLPAPI } from 'dns';
import { styled } from '@mui/material/styles';
import './style.css';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import Feedback from '../../../components/Feedback';
import { useQuery } from 'react-query';
import api from '../../../api';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));
const Game = ({
  gameConfig,
  endGame,
  flashScreen,
  isPracticeRound = false,
}) => {
  const [round, setRound] = useState(0);
  const [isGameEnd, setIsGameEnd] = useState(false);
  const roundTimeoutRef = useRef(null);
  const [lightBox, setLightBox] = useState(null);
  const [letter, setLetter] = useState('');
  const [trialAnalysis, setTrialAnalysis] = useState([]);
  const [timer, setResponseCountDown] = useState(0);
  const [jumpNext, setJumpNext] = useState(0);
  const [restartModalVisible, setRestartModalVisible] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [outsidePressCount, setOutsidePressCount] = useState(0);
  const restartPromptRef = useRef<any>();

  const startInactiveTimer = () => {
    if (!restartPromptRef.current && !isPracticeRound ) {
      restartPromptRef.current = setTimeout(() => {
        setRestartModalVisible(true);
        // document.addEventListener('keydown', identifyPressKey);
      }, 15000);
    }
  };

  const clearInactiveTimer = () => {
    clearTimeout(restartPromptRef.current);
    restartPromptRef.current = 0;
  };

  const closeRestartModal = () => {
    setRestartModalVisible(false);
    // document.removeEventListener('keydown', identifyPressKey);
    clearInactiveTimer();
  };

  const identifyPressKey =  async ({ key }) => {
    const pressedKey = key.toUpperCase();
    console.log(pressedKey);
    clearInactiveTimer();
    if(restartModalVisible){
      if(pressedKey == "C"){
        closeRestartModal();
      }
      if(pressedKey == "M"){
        resetTheGameFromBegining();
      }
      
    }else if (!gameConfig.possibleKeys.includes(pressedKey)) {
      startInactiveTimer();
      flashScreen();
      return;
    }

    let currentCountDown = 0;
    let currentRound = 0;
    setResponseCountDown((prev) => {
      currentCountDown = prev;
      return prev;
    });

    setRound((prev) => {
      currentRound = prev;
      return prev;
    });
    document.removeEventListener('keydown', identifyPressKey);
    setTrialAnalysis((prev) => prev.map((item) => {
      if (item.round === currentRound && !item.responses) {
        const d = new Date();
        let time = d.getTime();
        var differenceBetweenTime = time - currentCountDown;
        if(differenceBetweenTime > 2000){
          differenceBetweenTime = 2000;
        }
        const responses = [{
          currentCountDown: differenceBetweenTime ,
          pressedKey,
        }];

        return {
          ...item,
          responses,
        };
      }
      return item;
    }));

    setJumpNext((prev) => prev + 1);
    // stop the running interveal 
    clearTimeout(roundTimeoutRef.current);
    await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 5 seconds
    document.addEventListener('keydown', identifyPressKey);
    setRound((prev) => prev + 1);

    // const interval = setInterval((maxRounds,currentRound) => {
    //   if((maxRounds + 1) > currentRound  ){
    //     setRound((prev) => prev + 1);
    //   }
    // }, gameConfig.timeToRespond , gameConfig.roundsAmount,round);
    // roundTimeoutRef.current = interval;

    if (currentRound >= gameConfig.roundsAmount) {
      setTimeout(() => {
        document.removeEventListener('keydown', identifyPressKey);
      }, gameConfig.timeToRespond);
    }
    startInactiveTimer();
  };

  useEffect(() => {
    setTimeout(() => {
      setRound(1);
      document.addEventListener('keydown', identifyPressKey);
    }, 1000);
    startInactiveTimer();
    return () => {
      clearTimeout(restartPromptRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (round > gameConfig.roundsAmount) {
      console.log(trialAnalysis);
      endGame(trialAnalysis);
      setIsGameEnd(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round, trialAnalysis]);

  useEffect(() => {
    
    if (round > 0 && round <= gameConfig.roundsAmount) {
      const nextNumber = gameConfig.letterPerBox[round - 1].box - 1;
      const nextLetter = gameConfig.letterPerBox[round - 1].letter;

      setTrialAnalysis((prev: []) => [
        ...prev,
        { round, gameLetter: nextLetter },
      ]);
      setLetter(nextLetter);
      setLightBox(nextNumber);
    }

    setResponseCountDown(0);
    // const interval = setInterval((maxRound) => {
      
    //   setResponseCountDown((prev) => prev + 1);
    //   console.log("timer updating,,",timer);
      
    // }, 1000,gameConfig.roundsAmount);
    const d = new Date();
    let time = d.getTime();
    setResponseCountDown(time);
    // if(isGameEnd){
    //   return () => clearInterval(interval);
    // }

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round]);

  useEffect(() => {
    if (round > 0 && !isLocked) {
      setIsLocked(true);
      setLightBox(null);
      setLetter('');

      setTimeout(() => {
        setIsLocked(false);
        setOutsidePressCount(0);
        // setRound((prev) => prev + 1);
      }, gameConfig.timeBetweenRound);
    } else {
      setOutsidePressCount((prev) => prev + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jumpNext]);

  useEffect(() => {
    if (outsidePressCount > 3) {
      // Reaction to multiple button presses should go here
    }
  }, [outsidePressCount]);

  const resetTheGameFromBegining = () =>{// for inactive user, press to restart the game
    setRound(0);
    setTrialAnalysis([]);
    closeRestartModal();
    //reloading the page : to start from the begining 
    window.location.reload(false);
  }
  useEffect(() => {
    if (gameConfig.timeToRespond > 0 && !restartModalVisible) {
      startInactiveTimer();
      // setTimeout(() => {
      //   let currentCountDown = 0;

      //   setResponseCountDown((prev) => {
      //     currentCountDown = prev;
      //     return prev;
      //   });

        // if (currentCountDown * 10 >= gameConfig.timeToRespond) {
        //   setLightBox(null);
        //   setLetter('');
        // }
      // }, gameConfig.timeToRespond);

      const interval = setInterval((maxRounds,currentRound) => {
        if((maxRounds + 1) > currentRound  ){
          setRound((prev) => prev + 1);
        }
      }, gameConfig.timeToRespond , gameConfig.roundsAmount,round);
      roundTimeoutRef.current = interval;
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round, restartModalVisible]);

  // useEffect(() => {
  //   if (trialAnalysis.length >= gameConfig.roundsAmount && gameConfig.timeToRespond > 0) {
  //     setTimeout(() => {
  //       endGame(trialAnalysis);
  //     }, gameConfig.timeToRespond);
  //   }

  //   if (trialAnalysis.length > gameConfig.roundsAmount && gameConfig.timeToRespond <= 0) {
  //     setTimeout(() => {
  //       endGame(trialAnalysis);
  //     }, 500);
  //   }
  // }, [trialAnalysis.length]);

  return (
    <StyledPage
      theme={{ gameColor: gameConfig.gameColor }}
      className={`game-main-page ${!isPracticeRound ? 'full-height' : ''}`}
    >
      <Modal visible={restartModalVisible }>
        <H1>You have been inactive for a while!</H1>
        <Spacer top={10} />
        <P>What do you want to do?</P>
        <Spacer top={30} />
        <div style={{ display : "flex", justifyContent: "space-between"}}>
          <Button className="buttonType1Blue" variant="contained" size="large" sx={{ backgroundColor :"#8890BB !important", borderRadius :"4rem" }} onClick={() => resetTheGameFromBegining()}> See the instructions again</Button>
          <Button className="buttonType2Green" variant="contained" size="large" sx={{ backgroundColor :"#82D8A5 !important", borderRadius :"4rem", marginLeft : "1rem" }} onClick={() => closeRestartModal()}> Continue with the game</Button>
        </div>
        
      </Modal>
      <Header showBackButton={false} />

      <div className="game-container">
        <div className="progress-bar" style={{ width : "100%"}}>
          <BorderLinearProgress variant="determinate" 
                                value={(((round/gameConfig.roundsAmount)*100) > 100)?100:((round/gameConfig.roundsAmount)*100)}
                                sx={{ backgroundColor: '#eeeeee38 !important' , 
                                      borderRadius :"4rem", 
                                      height : "16px",
                                      '& .MuiLinearProgress-colorPrimary':{
                                        backgroundColor : '#eeeeee38'
                                      },
                                      '& .MuiLinearProgress-bar' : {
                                        backgroundColor : "#f1847c"
                                      }
                                      }} />
        </div>
        <div className="boxes-container">
          {Array.from(Array(gameConfig.boxesAmount).keys()).map((i) => (lightBox === i ? (
            <div
              key={i}
              className={`box box--selected ${
                gameConfig.feedbackColor
                  ? `box--selected--${gameConfig.feedbackColor}`
                  : ''
              }`}
            >
            <span className="box-text">
              {gameConfig.letterHint ? letter : ''}
            </span>
            </div>
          ) : (
            <div key={i} className="box">
              <span className="box-text" />
            </div>
          )))}
        </div>
      </div>

    </StyledPage>
  );
};

export default Game;
